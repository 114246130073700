

















































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { WechatImageTextService } from '@/services/wechat-imagetext-service';//获取专栏
import { statisticsApi } from '@/services/statistics-service';//新的接口 换这里
@Component({
    components: {

    }
})
export default class columnStatistics extends Vue {
    [x: string]: any;
    public form: any = { pages: 1, size: 10, current: 1, total: 0, channelId: '', orderName: '', orderType: '' };
    public datacreateTime: any[] = [];
    private tableData: any[] = [];
    public applications: any[] = [];
    public loading: Boolean = false;
    @Inject(WechatImageTextService) private wechatImageTextService!: WechatImageTextService;
    @Inject(statisticsApi) private statisticsApi!: statisticsApi;
    public async created(): Promise<void> {
        await this.applicationsList();
        await this.getList();
    }

    public search() {
        this.form.current = 1
        this.getList()
    }
    // 重置
    public reset() {
        this.form = {
            size: this.form.size,
            current: 1,
            channelId: '', orderName: '', orderType: ''
        };
        this.$refs.singleTable.clearSort();
        this.datacreateTime = [];
        this.getList();
    }
    //分页相关
    public handleSizeChange(size: number) {
        this.form.size = size;
        this.getList();
    }
    public handleCurrentChange(current: number) {
        this.form.current = current;
        this.getList();
    }
    //获取数据源
    public async getList() {
        var that = this, dataJson = {};
        that.loading = true;
        if (that.datacreateTime && that.datacreateTime.length > 0) {
            that.form.endTime = that.datacreateTime[1]
            that.form.startTime = that.datacreateTime[0]
        } else {
            that.form.endTime = ''
            that.form.startTime = ''
        }
        dataJson = {
            entity: {
                agentId: that.form.channelId.toString(),//所属应用/专栏
                orderName: that.form.orderName,//排序字段
                orderType: that.form.orderType,//正序倒序:空值:正序 desc:倒序
                searchStartTime: that.form.startTime,//开始时间
                searchEndTime: that.form.endTime,//结束时间},
            },
            size: that.form.size,
            current: that.form.current
        }
        const res = await this.statisticsApi.getcolumnList(dataJson);
        that.tableData = res.records;
        this.form.total = res.total;
        this.form.pages = res.pages;
        this.form.size = res.size;
        this.form.current = res.current;
        this.loading = false;
    }
    //获取专栏(这里是应用)
    public async applicationsList(): Promise<void> {
        const res = await this.wechatImageTextService.applicationsList();
        this.applications = res
    }
    //导出
    public exportClick(row: any): void {
        var that = this, data_ = {}, str: any = '';
        if (that.tableData.length <= 0) {
            this.$message({
                type: 'error',
                message: '当前无下载内容！'
            })
            return;
        }
        if (that.datacreateTime.length > 0) {
            this.form.endTime = that.datacreateTime[1]
            this.form.startTime = that.datacreateTime[0]
        } else {
            this.form.endTime = ''
            this.form.startTime = ''
        }
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth()+1;
        var day = date.getDate();
        var timeSend = year + '-' + month + '-' + day;
        data_ = {
            "agentId": that.form.channelId.toString(),
            "orderName": that.form.orderName,
            "orderType": that.form.orderType,//正序倒序:空值:正序 desc:倒序
            "searchEndTime": this.form.endTime,
            "searchStartTime": this.form.startTime
        }
        that.loading = false;
        str = that.splicing(data_)
        this.statisticsApi.columnDownloadAll(str, '专栏分析'+timeSend);
        // window.open(document.location.origin + `/api/application/statis/agent-download-list?` + str)
    }
    // 导出拼接
    public splicing(data: any) {
        var str = [], strString = '';
        for (var key in data) {
            str.push(key + "=" + data[key])
        }
        strString = str.join('&')
        return strString;
    }
    // readingClick 阅读人数排序点击
    public readingClick(e: any): void {
        var that = this;
        that.form.orderName = e.column.property;
        if (e.order == 'descending') {
            that.form.orderType = 'desc';
        } else if (e.order == "ascending") {
            that.form.orderType = '';
        } else {
            that.form.orderName = '';
            that.form.orderType = '';
        }
        that.form.current = 1;
        this.getList();
    }

    private mounted() {
        document.onkeydown = (e) => {
            let key = (window.event as any).keyCode;
            if (key == 13) {
                this.search()
            }
        }
    }
}
